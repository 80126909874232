$primary:"#fafafa";
$secondary:"#fcfcfc";
$tertiary:"#ffff";
$primary-font: 'Barlow', sans-serif;
$secondary-font:'Barlow Condensed', sans-serif;

h1,h2,h3,h5{
  font-family: $primary-font;
}
p,span{
  font-family: $primary-font;
}

.greyspan{
  color: #a1a1a1;
}

.display-mobile{
  display: none;
}
.dashboard-container{
  height: 100vh;
  overflow-y: scroll;
}
::-webkit-scrollbar{
  width:5px !important;
}

::-webkit-scrollbar-track{
  background:transparent !important;
}


::-webkit-scrollbar-thumb{
  background:rgb(235, 235, 235) !important;
  opacity:0.2;
  border-radius:25px
}

::-webkit-scrollbar-thumb:hover{
  background:#dfdfdf !important;
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
  .alert{
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0%;
  }
  .display-mobile{
    display: block;
  }

}