@import  "../../App.scss";

.verifyotp{
    width: 30%;
    margin: auto;
    text-align: center;
    padding: 2%;
    background-color: #f7f7f7;
}

.offline-pay{
    background-color: #f7f7f7;
    .offline-pay-2{
        padding: 2%;
        background-color: white;
        width: 45%;
        margin: auto;
        border-radius: 10px;
        h3{
            font-size: 1.8em;
            font-weight: 500 !important;
            padding: 1% 0;
        }
        img{
            width: 15%;
        }
        .info{
            margin: auto;
        }
        .discounttable{
            color: green;
        }
        .totaltable{
            font-weight: 600;
        }
        .payment-summary{
            .subtotal{
    
            }
            .discount{
                color: green;
                font-weight: bold;
            }
            .total{
                font-size: 2em;
                font-weight: bold;
            }
        }
        .signdiv{
            border-top: 2px solid black;
            text-align: center;
            padding: 5px;
            margin-top: 10%;
        }
        .pbtn{
            text-align: center;
            margin-top: 4%;
        }
    }

}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
    .offline-pay{
        background-color: #f7f7f7;
        .offline-pay-2{
            padding: 3% 4%;
            background-color: white;
            width: 90%;
            margin: auto;
            border-radius: 10px;
            .merchant-info{
                margin-top: 8%;
            }
            h3{
                font-size: 1.8em;
                font-weight: 500 !important;
                padding: 1% 0;
            }
            img{
                width: 15%;
            }
            .info{
                margin: auto;
            }
            .discounttable{
                color: green;
            }
            .totaltable{
                font-weight: 600;
            }
            .payment-summary{
                .subtotal{
        
                }
                .discount{
                    color: green;
                    font-weight: bold;
                }
                .total{
                    font-size: 2em;
                    font-weight: bold;
                }
            }
            .signdiv{
                border-top: 2px solid black;
                text-align: center;
                padding: 5px;
                margin-top: 10%;
            }
            .pbtn{
                text-align: center;
                margin-top: 4%;
            }
        }
    
    }
    
}

